import { DataAccessService } from "../core/api";
import { User } from "../core/user";

export default async function dismissBypasses(bypasses) {
  try {
    const client = new DataAccessService();
    const requestBody = {
      metricsToDismiss: bypasses.map((bypass) => ({
        username: bypass.username,
        epoch: bypass.time,
        commitId: bypass.commitId,
      })),
    };
    const requestParams = {
      username: new User().userId,
    };
    // TODO: backend implementation
    // await client.patch(`/metrics/bypass/dismiss`, requestBody, requestParams);
  } catch (err) {
    console.warn(err);
    throw err;
  }
}
