// React
import React, { Component, useEffect, useState } from "react";

// Components
import { ServiceNavigation } from "Component/service-navigation/ServiceNavigation.jsx";
import ServiceBreadcrumbs from "Component/ServiceBreadcrumbs.jsx";

// Polaris
import { AppLayout, Button } from "@amzn/awsui-components-react";
import { BreadCrumbController } from "Component/ServiceBreadcrumbs.jsx";
import { FlashbarDisplay, FlashbarPublisher } from "./FlashbarPublisher";
import getBypasses from "../helpers/getBypasses";
import { useNavigate } from "react-router-dom";
import { isTopRopeFeatureOn } from "../helpers/isTopRopeFlagOn";

const DynamicAppLayout = (props) => {
  const navigate = useNavigate();

  const showBypassesFlashbar = async () => {
    try {
      const result = await getBypasses(true);
      const count = result.filter(
        (bypass) => !bypass.noVerifyReasonType
      ).length;
      if (count > 0) {
        FlashbarPublisher.pushItem({
          header: `You have ${count} unacknowledged bypass${
            count > 1 ? "es" : ""
          } pending resolution.`,
          id: "unacknowledged_bypasses_flashbar",
          type: "info",
          content: 'Go to the "My bypasses" page to resolve.',
          action: (
            <Button
              onClick={(ev) => {
                ev.preventDefault();
                navigate("/bypasses/unacknowledged");
              }}
            >
              View my bypasses
            </Button>
          ),
        });
      }
    } catch (e) {
      // Fail silently
      console.error("Failed to get bypasses");
    }
  };

  useEffect(() => {
    if (
      isTopRopeFeatureOn() &&
      props.page !== "UnacknowledgedBypassesPage" &&
      props.page !== "ServiceHomePage"
    ) {
      window.addEventListener(
        "ServiceNotificationChange",
        showBypassesFlashbar
      );
      window.dispatchEvent(new Event("ServiceNotificationChange"));

      return () => {
        window.removeEventListener(
          "ServiceNotificationChange",
          showBypassesFlashbar
        );
      };
    }
  }, []);

  const [navigationOpen, setNavigationOpen] = useState(
    props.page !== "ServiceHomePage"
  );
  let defaultProps = {};

  if (props.page in BreadCrumbController) {
    defaultProps.breadcrumbs = <ServiceBreadcrumbs {...props} />;
  }
  Object.assign(
    defaultProps,
    {
      navigation: <ServiceNavigation />,
      contentType: "default",
      navigationOpen: navigationOpen,
      onNavigationChange: ({ detail }) => setNavigationOpen(detail.open),
      notifications: <FlashbarDisplay />,
      stickyNotifications: true,
      toolsHide: true,
      headerSelector: "#header",
      footerSelector: "#footer",
    },
    props
  );

  return <AppLayout {...defaultProps} />;
};

// Class ServiceAppLayout is the AppLayout component that is used in the pages.
// Implement like this: <ServiceAppLayout page='GenericNotFound'/>
class ServiceAppLayout extends Component {
  constructor(props) {
    super();
  }

  render() {
    return <DynamicAppLayout {...this.props} />;
  }
}

export default ServiceAppLayout;
