import {
  BreadcrumbGroup,
  Container,
  Header,
  Link,
  SpaceBetween,
  Tabs,
} from "@amzn/awsui-components-react";
import { RepositoryRequestTable } from "../../components/repository-request/RepositoryRequest";
import { RepoSearchTable } from "../../components/repositories-ui/RepoSearchTable";
import { Navigate } from "react-router-dom";
import { hasAdminLikeRole } from "../../helpers/hasAdminLikeRole";
import { useEffect, useState } from "react";
import { getRepoManagersForUser } from "../../helpers/repos/getRepoManagersForUser";
import { User } from "../../core/user";
import { FlashbarPublisher } from "../../components/FlashbarPublisher";
import { isTopRopeFeatureOn } from "../../helpers/isTopRopeFlagOn";
import { OrgAllowListTable } from "../../components/repositories-ui/OrgAllowListTable";

export const RulesEngine = () => {
  const user = new User();
  const [managers] = useState(getRepoManagersForUser(user.userDetails));
  const [search, setSearch] = useState({ managers });

  const flashbarContent = (
    <>
      Please expect a delay in approval for repository access requests as we
      work to better align with Amazon security best practices and guidance.{" "}
      <Link
        external
        color="inverted"
        href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/Release_Notes/#HJune52C2024:Delayinapprovalforrepositoryaccessrequests"
        variant="primary"
      >
        Learn more
      </Link>
    </>
  );
  useEffect(() => {
    if (!isTopRopeFeatureOn()) {
      FlashbarPublisher.pushItem({
        type: "warning",
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: FlashbarPublisher.clear,
        content: flashbarContent,
      });
    }
  }, []);

  return (
    <div data-testid={"rules-engine"}>
      <SpaceBetween direction="vertical" size="s">
        <BreadcrumbGroup
          test-id="breadcrumb-group"
          items={[
            { text: "Code Defender", href: "/" },
            { text: "Rules engine", href: "/rules" },
          ]}
          ariaLabel="Breadcrumbs"
          onClick={(detail) => Navigate(detail.href)}
        />
        <div data-testid={"repository-tab"}>
          <SpaceBetween size="xxl" direction="vertical">
            <div data-testid={"repository-request-table"}>
              <RepositoryRequestTable
                setSearch={setSearch}
                isAdmin={hasAdminLikeRole()}
                search={search}
                managers={managers}
              />
            </div>
            {isTopRopeFeatureOn() && (
              <Container
                disableContentPaddings
                header={<Header>Allow list</Header>}
              >
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <Tabs
                    disableContentPaddings
                    tabs={[
                      {
                        label: "Team-specific repositories",
                        id: "repository-allow-list",
                        content: (
                          <div
                            style={{ paddingTop: "12px"}}
                            data-testid={"repository-search-table"}
                          >
                            <RepoSearchTable
                              adminView={hasAdminLikeRole()}
                              managers={managers}
                              search={search}
                              setSearch={setSearch}
                              user={user}
                            />
                          </div>
                        ),
                      },
                      {
                        label: "GitHub organizations",
                        id: "org-allow-list",
                        content: (
                          <div
                            style={{ paddingTop: "12px", paddingBottom: "4px" }}
                            data-testid={"org-table"}
                          >
                            <OrgAllowListTable />
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              </Container>
            )}
            {!isTopRopeFeatureOn() && (
              <div data-testid={"repository-search-table"}>
                <RepoSearchTable
                  adminView={hasAdminLikeRole()}
                  managers={managers}
                  search={search}
                  setSearch={setSearch}
                  user={user}
                />
              </div>
            )}
          </SpaceBetween>
        </div>
      </SpaceBetween>
    </div>
  );
};
