import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Badge, SideNavigation } from "@amzn/awsui-components-react";
import getBypasses from "../../helpers/getBypasses";
import { isBypassesPhase2On } from "../../helpers/isBypassesPhase2On";
import { isDefenderInstalled } from "../../helpers/isDefenderInstalled";
import { isDelegationAccessible } from "../../helpers/isDelegationAccessible";
import { isInternalUser } from "../../helpers/isInternalUser";
import { isNewDashboardAccessible } from "../../helpers/isNewDashboardAccessible";
import { isRuleManagementAccessible } from "../../helpers/isRuleManagementAccessible";
import { isRulesEngineAccessible } from "../../helpers/isRulesEngineAccessible";
import { isTopRopeFeatureOn } from "../../helpers/isTopRopeFlagOn";
import { DarkModeToggle } from "../dark-mode-toggle";

export const ServiceNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [unacknowledgedBypassesCount, setUnacknowledgedBypassesCount] =
    useState(0);
  const [complianceAlertCount, setComplianceAlertCount] = useState(0);

  const getBypassCount = async function () {
    try {
      const result = await getBypasses(true);
      const count = result.filter(
        (bypass) => !bypass.noVerifyReasonType
      ).length;
      setUnacknowledgedBypassesCount(count);

      // temporary until we have a backend implementation
      const complianceAlerts = result.filter((bypass) => bypass.alertType).length;
      setComplianceAlertCount(complianceAlerts);
    } catch (e) {
      // Fail silently
      console.error("Failed to get bypasses");
    }
  };

  useEffect(() => {
    if (isTopRopeFeatureOn()) {
      window.addEventListener("ServiceNotificationChange", getBypassCount);
      window.dispatchEvent(new Event("ServiceNotificationChange"));

      return () => {
        window.removeEventListener("ServiceNotificationChange", getBypassCount);
      };
    }
  }, []);

  let dynamicItems = [];
  if (!isDefenderInstalled()) {
    dynamicItems.push({
      type: "link",
      text: "Getting started",
      href: "/installation",
    });
  }
  if (isTopRopeFeatureOn() && isBypassesPhase2On()) {
    dynamicItems.push({
      type: "link",
      text: "Compliance alerts",
      info: (
        <>
          {complianceAlertCount > 0 && (
            <Badge color="red">{complianceAlertCount}</Badge>
          )}
        </>
      ),
      href: "/compliance_alerts",
    });
  }
  if (isInternalUser()) {
    const dashboardPath = isNewDashboardAccessible()
      ? "/dashboard"
      : "/view_metrics";
    dynamicItems.push({
      type: "link",
      text: "Dashboard",
      href: dashboardPath,
    });
  }

  if (isRuleManagementAccessible()) {
    dynamicItems.push({
      type: "section",
      text: "Management tools",
      items: [
        {
          type: "link",
          text: "Team rules",
          href: "/team_rules",
        },
        {
          type: "link",
          text: "Repository rules",
          href: "/repo_rules",
        },
        {
          type: "link",
          text: "Repository allow list",
          href: "/repo_allow_list",
        },
        {
          type: "link",
          text: "Resource management permissions",
          href: "/permissions", //TODO: https://app.asana.com/0/1202910158810356/1206539558669058/f
        },
        {
          type: "link",
          text: "Delegate",
          href: "/delegation",
        },
      ],
    });
  } else {
    if (isDelegationAccessible()) {
      dynamicItems.push({
        type: "link",
        text: "Delegation",
        href: "/delegation",
      });
    }

    if (isRulesEngineAccessible()) {
      if (isTopRopeFeatureOn()) {
        dynamicItems.push({
          type: "section",
          text: "Rules engine",
          items: [
            { type: "link", text: "Repository allow list", href: "/rules" },
          ],
        });
      } else {
        dynamicItems.push({
          type: "link",
          text: "Rules engine",
          href: "/rules",
        });
      }
    }
  }

  if (isTopRopeFeatureOn()) {
    dynamicItems.push({
      type: "section",
      text: "Bypass",
      items: [
        {
          type: "link",
          text: (
            <>
              My unacknowledged bypasses{" "}
              {unacknowledgedBypassesCount > 0 && (
                <Badge color="red">{unacknowledgedBypassesCount}</Badge>
              )}
            </>
          ),
          href: "/bypasses/unacknowledged",
        },
        { type: "link", text: "All bypasses", href: "/bypasses/all" },
      ],
    });
  }

  return (
    <SideNavigation
      id={"SideNavigation"}
      activeHref={location.pathname}
      header={{ href: "/installation", text: "Code Defender" }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          navigate(event.detail.href);
        }
      }}
      items={[
        ...dynamicItems,
        { type: "divider" },
        {
          type: "section",
          text: "Helpful resources",
          items: [
            {
              type: "link",
              text: "FAQ",
              href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/",
              external: true,
            },
            {
              type: "link",
              text: "User onboarding",
              href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserOnboarding/",
              external: true,
            },
            {
              type: "link",
              text: "About Code Defender",
              href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/",
              external: true,
            },
          ],
        },
        { type: "divider" },
        {
          type: "link",
          info: <DarkModeToggle />,
        },
      ]}
    />
  );
};
