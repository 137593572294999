//React
import React from "react";

// Polaris
import {
    Button,
    ColumnLayout,
    Container,
    Header,
    Link,
    Pagination,
    Table,
    Tabs,
} from '@amzn/awsui-components-react';

import { negativeIconBuilder, positiveIconBuilder } from 'Helper/iconBuilders'

import { LocalStorage } from "Core/storage";
import { LeaveIcon } from "../../../helpers/iconBuilders";
import { useOutletContext } from "react-router-dom";

export const Reports = () => {

    const {
        chain,
        currentPageIndex,
        delegations,
        loadData,
        loading,
        reportItems,
        reportsOnLeaveCount,
        selectedItems,
        selectedUser,
        setChain,
        setCurrentPageIndex,
        setLoading,
        setModalToBlockedCommit,
        setModalVisibility,
        setSelectedUser,
        userIssueList,
        userIssueListTableLen,
    } = useOutletContext();

    return (<ColumnLayout>
        <ColumnLayout columns={3}>
            {delegations && delegations.length > 0 &&
                <Container disableContentPaddings={true} header={<Header>Your Assigned Segment Leaders</Header>}>
                    <div style={{ height: "154px", overflow: "auto" }}>
                        <Table
                            columnDefinitions={[
                                {
                                    id: "user",
                                    header: "User",
                                    cell: item => <b>{item}</b>
                                },
                                {
                                    id: "installed",
                                    cell: item =>
                                        <div style={{ display: "flex", justifyContent: 'center' }}>
                                            <Button
                                                id={`${item}DelegationButton`}
                                                variant="primary"
                                                wrapText={false}
                                                iconAlign="right"
                                                iconName="angle-right"
                                                onClick={async () => {
                                                    setSelectedUser(item);
                                                    setChain(chain.concat([{ text: item, href: '#' }]));
                                                    setLoading(true);
                                                    await loadData(item);
                                                    setLoading(false);
                                                }}>
                                                View Reports
                                            </Button>
                                        </div>
                                }
                            ]}
                            items={delegations}
                            loadingText="Loading data"
                            loading={loading}
                            empty={<div>No data</div>}
                        />
                    </div>
                </Container>}
        </ColumnLayout>

        {
            reportItems && reportItems.length > 0 && <Container disableContentPaddings={true}>
                <Table
                    ariaLabels={{
                        selectionGroupLabel: "Items selection",
                        allItemsSelectionLabel: () =>
                            `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                            } selected`,
                        itemSelectionLabel: (item) => {
                            var isItemSelected = selectedItems.filter(
                                i => i.name === item.name
                            ).length;
                            return `${item.name} is ${isItemSelected ? "" : "not"
                                } selected`;
                        }
                    }}
                    columnDefinitions={[
                        {
                            id: "user",
                            header: "User",
                            cell: item =>
                                <Button
                                    id={`${item.username}Button`}
                                    variant="link"
                                    wrapText={false}
                                    iconAlign="right"
                                    disabled={item.username === "total"}
                                    iconName="angle-right"
                                    onClick={async () => {
                                        setSelectedUser(item.username);
                                        setChain(chain.concat([{ text: item.username, href: '#' }]));
                                        setLoading(true);
                                        await loadData(item.username);
                                        setLoading(false);
                                    }}>
                                    {item.isUserOnLeave ? (LocalStorage.get("theme") === "dark") ?
                                        <LeaveIcon style={{ height: '16px', width: '16px', fill: 'white' }} /> :
                                        <LeaveIcon style={{ height: '16px', width: '16px', fill: 'black' }} /> : ""}
                                    {item.user || "0"}
                                </Button>
                        },
                        {
                            id: "bypassAttempts",
                            header: "Bypass Attempts",
                            cell: item => item.username !== "total" && (item.bypassAttempts || item.bypassAttempts > 0 ? negativeIconBuilder(item.bypassAttempts) : positiveIconBuilder(0))
                        },
                        {
                            id: "blockedCommits",
                            header: "Blocked Commits (30 Days)",
                            cell: item => item.username !== "total" && (item.reportPersonalNumBlockedCommits || item.reportPersonalNumBlockedCommits > 0 ? negativeIconBuilder(item.reportPersonalNumBlockedCommits) : positiveIconBuilder(0))
                        },
                        {
                            id: "reports",
                            header: "Number of Reports",
                            cell: item => item.reports || "0"
                        },
                        {
                            id: "reportsOnLeave",
                            header: "Reports on leave",
                            cell: item => item.reportsOnLeave > 0 ? negativeIconBuilder(item.reportsOnLeave) : positiveIconBuilder(0)
                        },
                        {
                            id: "reportsBypasses",
                            header: "Report's Bypass Attempts",
                            cell: item => item.reportBypassAttempts && item.reportBypassAttempts > 0 ? negativeIconBuilder(item.reportBypassAttempts) : positiveIconBuilder(0)
                        },
                        {
                            id: "reportsBlockedCommits",
                            header: "Report's Blocked Commits (30 Days)",
                            cell: item =>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ flex: "1" }}>
                                        {item.reportNumBlockedCommits > 0 ? negativeIconBuilder(item.reportNumBlockedCommits) : positiveIconBuilder(item.reportNumBlockedCommits)}
                                    </div>
                                    <div style={{ flex: "2" }}>
                                        <Button id={`${item.username}ReportsBlockedCommitsButton`} variant="primary" onClick={() => {
                                            setModalToBlockedCommit(item.reportBlockedCommits, item.user);
                                            setModalVisibility(true);
                                        }}>
                                            View Commits
                                        </Button>
                                    </div>
                                </div>
                        }
                    ]}
                    items={reportItems}
                    loadingText="Loading data"
                    loading={loading}
                    empty={<div>No data</div>}
                    header={
                        <Header description={
                            <div style={{ alignItems: "center", display: "flex" }}>
                                {(LocalStorage.get("theme") === "dark") ?
                                    <LeaveIcon style={{ height: '14px', width: '14px', fill: 'white' }} /> :
                                    <LeaveIcon style={{ height: '14px', width: '14px', fill: 'black' }} />}
                                {reportsOnLeaveCount} direct reports on leave
                            </div>}
                        >
                            Direct Reports for {selectedUser} ({reportItems && reportItems.length > 0 ? reportItems.length - 1 : 0})
                        </Header>
                    }
                />
            </Container>
        }

        {(reportItems && reportItems.length > 0) &&
            <Container disableContentPaddings={true} header={
                <Header info={<Link variant="info"
                    href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#20">Info</Link>}>
                    All Reports With Commit Blocks, Multiple Bypasses and Failed Check-ins
                </Header>}>
                <Tabs
                    tabs={[
                        {
                            label: `Secret Key (${userIssueList.length > 0 ? userIssueList[0].userList.length : 0})`,
                            id: "secret",
                            content:
                                userIssueList.length > 0 &&
                                <Table
                                    header={<Header>{userIssueList[0].metricTitle}</Header>}
                                    columnDefinitions={[
                                        {
                                            id: "reports",
                                            header: "Reports with Risks (30 days)",
                                            cell: item =>
                                                <Button
                                                    id={`${item}SecretButton`}
                                                    variant="link"
                                                    wrapText={false}
                                                    iconAlign="right"
                                                    iconName="angle-right"
                                                    onClick={async () => {
                                                        setSelectedUser(item);
                                                        setChain(chain.concat([{ text: item, href: '#' }]));
                                                        setLoading(true);
                                                        await loadData(item);
                                                        setLoading(false);
                                                    }}>
                                                    {item || "0"}
                                                </Button>
                                        },
                                        {
                                            id: "phonetool",
                                            header: "Contact",
                                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                                        }
                                    ]}
                                    items={
                                        userIssueList[0].userList.slice((currentPageIndex - 1) * userIssueListTableLen, currentPageIndex * userIssueListTableLen)
                                    }
                                    loadingText="Loading data"
                                    loading={loading}
                                    empty={<div>No data</div>}
                                    disableContentPaddings={true}
                                    pagination={
                                        <Pagination
                                            pagesCount={Math.ceil(userIssueList[0].userList.length / userIssueListTableLen)}
                                            ariaLabels={{
                                                nextPageLabel: "Next page",
                                                previousPageLabel: "Previous page",
                                                pageLabel: pageNumber =>
                                                    `Page ${pageNumber} of all pages`
                                            }}
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                setCurrentPageIndex(detail.currentPageIndex)
                                            }
                                        />
                                    }
                                />
                        },
                        {
                            label: `Access Key (${userIssueList.length > 1 ? userIssueList[1].userList.length : 0})`,
                            id: "access",
                            content:
                                userIssueList.length > 1 &&
                                <Table
                                    header={<Header>{userIssueList[1].metricTitle}</Header>}
                                    columnDefinitions={[
                                        {
                                            id: "reports",
                                            header: "Reports with Risks (30 days)",
                                            cell: item =>
                                                <Button
                                                    id={`${item}AccessButton`}
                                                    variant="link"
                                                    wrapText={false}
                                                    iconAlign="right"
                                                    iconName="angle-right"
                                                    onClick={async () => {
                                                        setSelectedUser(item);
                                                        setChain(chain.concat([{ text: item, href: '#' }]));
                                                        setLoading(true);
                                                        await loadData(item);
                                                        setLoading(false);
                                                    }}>
                                                    {item || "0"}
                                                </Button>
                                        },
                                        {
                                            id: "phonetool",
                                            header: "Contact",
                                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                                        }
                                    ]}
                                    items={
                                        userIssueList[1].userList.slice((currentPageIndex - 1) * userIssueListTableLen, currentPageIndex * userIssueListTableLen)
                                    }
                                    loadingText="Loading data"
                                    loading={loading}
                                    empty={<div>No data</div>}
                                    disableContentPaddings={true}
                                    pagination={
                                        <Pagination
                                            pagesCount={Math.ceil(userIssueList[1].userList.length / userIssueListTableLen)}
                                            ariaLabels={{
                                                nextPageLabel: "Next page",
                                                previousPageLabel: "Previous page",
                                                pageLabel: pageNumber =>
                                                    `Page ${pageNumber} of all pages`
                                            }}
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                setCurrentPageIndex(detail.currentPageIndex)
                                            }
                                        />
                                    }
                                />
                        },
                        {
                            label: `Private RSA Key (${userIssueList.length > 2 ? userIssueList[2].userList.length : 0})`,
                            id: "privateRSA",
                            content:
                                userIssueList.length > 2 &&
                                <Table
                                    header={<Header>{userIssueList[2].metricTitle}</Header>}
                                    columnDefinitions={[
                                        {
                                            id: "reports",
                                            header: "Reports with Risks (30 days)",
                                            cell: item =>
                                                <Button
                                                    id={`${item}PrivateRSAButton`}
                                                    variant="link"
                                                    wrapText={false}
                                                    iconAlign="right"
                                                    iconName="angle-right"
                                                    onClick={async () => {
                                                        setSelectedUser(item);
                                                        setChain(chain.concat([{ text: item, href: '#' }]));
                                                        setLoading(true);
                                                        await loadData(item);
                                                        setLoading(false);
                                                    }}>
                                                    {item || "0"}
                                                </Button>
                                        },
                                        {
                                            id: "phonetool",
                                            header: "Contact",
                                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                                        }
                                    ]}
                                    items={
                                        userIssueList[2].userList.slice((currentPageIndex - 1) * userIssueListTableLen, currentPageIndex * userIssueListTableLen)
                                    }
                                    loadingText="Loading data"
                                    loading={loading}
                                    empty={<div>No data</div>}
                                    disableContentPaddings={true}
                                    pagination={
                                        <Pagination
                                            pagesCount={Math.ceil(userIssueList[2].userList.length / userIssueListTableLen)}
                                            ariaLabels={{
                                                nextPageLabel: "Next page",
                                                previousPageLabel: "Previous page",
                                                pageLabel: pageNumber =>
                                                    `Page ${pageNumber} of all pages`
                                            }}
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                setCurrentPageIndex(detail.currentPageIndex)
                                            }
                                        />
                                    }
                                />
                        },
                        {
                            label: `Multiple Bypass (${userIssueList.length > 3 ? userIssueList[3].userList.length : 0})`,
                            id: "bypass",
                            content:
                                userIssueList.length > 3 &&
                                <Table
                                    header={<Header>{userIssueList[3].metricTitle}</Header>}
                                    columnDefinitions={[
                                        {
                                            id: "reports",
                                            header: "Reports with Risks (30 days)",
                                            cell: item =>
                                                <Button
                                                    id={`${item}BypassButton`}
                                                    variant="link"
                                                    wrapText={false}
                                                    iconAlign="right"
                                                    iconName="angle-right"
                                                    onClick={async () => {
                                                        setSelectedUser(item);
                                                        setChain(chain.concat([{ text: item, href: '#' }]));
                                                        setLoading(true);
                                                        await this.loadData(item);
                                                        setLoading(false);
                                                    }}>
                                                    {item || "0"}
                                                </Button>
                                        },
                                        {
                                            id: "phonetool",
                                            header: "Contact",
                                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                                        }
                                    ]}
                                    items={
                                        userIssueList[3].userList.slice((currentPageIndex - 1) * userIssueListTableLen, currentPageIndex * userIssueListTableLen)
                                    }
                                    loadingText="Loading data"
                                    loading={loading}
                                    empty={<div>No data</div>}
                                    disableContentPaddings={true}
                                    pagination={
                                        <Pagination
                                            pagesCount={Math.ceil(userIssueList[3].userList.length / userIssueListTableLen)}
                                            ariaLabels={{
                                                nextPageLabel: "Next page",
                                                previousPageLabel: "Previous page",
                                                pageLabel: pageNumber =>
                                                    `Page ${pageNumber} of all pages`
                                            }}
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                setCurrentPageIndex(detail.currentPageIndex)
                                            }
                                        />
                                    }
                                />
                        },
                        {
                            label: `Failed Check-ins (${userIssueList.length > 4 ? userIssueList[4].userList.length : 0})`,
                            id: "checkins",
                            content:
                                userIssueList.length > 4 &&
                                <Table
                                    header={<Header>{userIssueList[4].metricTitle}</Header>}
                                    columnDefinitions={[
                                        {
                                            id: "reports",
                                            header: "Reports with Risks (30 days)",
                                            cell: item =>
                                                <Button
                                                    id={`${item}CheckinsButton`}
                                                    variant="link"
                                                    wrapText={false}
                                                    iconAlign="right"
                                                    iconName="angle-right"
                                                    onClick={async () => {
                                                        setSelectedUser(item);
                                                        setChain(chain.concat([{ text: item, href: '#' }]));
                                                        setLoading(true);
                                                        await loadData(item);
                                                        setLoading(false);
                                                    }}>
                                                    {item || "0"}
                                                </Button>
                                        },
                                        {
                                            id: "phonetool",
                                            header: "Contact",
                                            cell: item => <a variant="link" href={`https://phonetool.amazon.com/users/${item}`}>Phone Tool</a>
                                        }
                                    ]}
                                    items={
                                        userIssueList[4].userList.slice((currentPageIndex - 1) * userIssueListTableLen, currentPageIndex * userIssueListTableLen)
                                    }
                                    loadingText="Loading data"
                                    loading={loading}
                                    empty={<div>No data</div>}
                                    disableContentPaddings={true}
                                    pagination={
                                        <Pagination
                                            pagesCount={Math.ceil(userIssueList[4].userList.length / userIssueListTableLen)}
                                            ariaLabels={{
                                                nextPageLabel: "Next page",
                                                previousPageLabel: "Previous page",
                                                pageLabel: pageNumber =>
                                                    `Page ${pageNumber} of all pages`
                                            }}
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                setCurrentPageIndex(detail.currentPageIndex)
                                            }
                                        />
                                    }
                                />
                        },
                    ]}
                    onChange={({ detail }) =>
                        setCurrentPageIndex(1)}
                />
            </Container>
        }
    </ColumnLayout>);
}